<template>
  <div class="content">
    <el-card class="custom-card content-body">
      <div slot="header" class="clearfix">
        <span>粮库选择</span>
        <!-- <el-button class="right" type="text">操作按钮</el-button> -->
      </div>
      <el-form ref="form" :model="form" :rules="rules" label-width="50px">
        <el-form-item label="粮库">
          <el-select v-model="form.liangId" placeholder="请选择" class="width-100">
            <el-option v-for="(item,index) in list" :key="index" :label="item.liangName" :value="item.liangId+''"></el-option>
          </el-select>
        </el-form-item>
        <div class="center">
          <el-button type="primary" @click="onSubmit('form')" :loading="loading" :disabled="loading">确认</el-button>
          <el-button @click="$router.back()">返回</el-button>
        </div>
      </el-form>
    </el-card>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        form: {
          liangId: this.$cookies.get(this.$cache.LIANG_ID)
        },
        loading:false,
        rules: {
          liangId: [{
            required: true,
            message: '不可为空',
            trigger: 'change'
          }]
        },
        list: []
      }
    },
    created() {
      this.findLiangs()
    },
    methods: {
      //t提交
      onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading=true;
            this.$cookies.set(this.$cache.LIANG_ID, this.form.liangId, {
              expires: this.$cache.TOKEN_EXPIRES
            })
            let current=this.list.findIndex(item=>item.liangId==this.form.liangId)
            if(current!==-1){
              this.$cookies.set(this.$cache.LIANG_NAME, this.list[current].liangName, {
                expires: this.$cache.TOKEN_EXPIRES
              })
            }
            let than=this;
            setTimeout(function(){
               than.loading=false;
               than.$router.push({
                 path:'/'
               })
            },1000)
          } else {
            return false;
          }
        });
      },
      //h获取粮库信息
      findLiangs(){
        this.$axios.Get(this.$api.FindLiangs,{}).then(res=>{
          this.list=res.data
        })
      }
    }
  }
</script>

<style scoped lang="less">
  .content {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);

    &-body {
      width: 400px;
    }
  }
</style>
